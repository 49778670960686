<template>
  <div>
    <div class="container-wrapper">
      <div class="v-wrapper container">
        <p> <span class="back" @click="returnIndex"></span></p>
        <div @click="router.push('/article/' + v.id)" class="v-item-inner" v-for="v in articles" :key="v.id">
          <VideoImage class="image-wrapper" :src="v.thumb" />
          <div class="text-content">
            <h2>{{v.title}}</h2>
            <p class="time">{{v.createTime}}</p>
          </div>
        </div>
      </div>
      <p class="pagenation">
        <Pagenation @change="pageChange" :pageSize="10" :total="total" />
      </p>
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
import { getNewsList as _getNewsList } from "@api"
import Pagenation from '@c/Pagenation.vue'
import VideoImage from "@c/VideoImage.vue";
import { ref, computed } from '@vue/reactivity';
import { useRouter } from 'vue-router';
export default {
  name: "article",
  components: {
    Pagenation,
    VideoImage
  },
  setup () {
    const currentPage = ref(0)
    const total = ref(0)
    const articles = ref([])
    const router = useRouter()
    const currentNo = computed(() => currentPage.value + 1)
    const getNewsList = (refresh) => {
      _getNewsList({
        currentPage: refresh ? currentPage.value = 0 : currentPage.value,
        pageSize: 10
      }).then(res => {
        articles.value = res.data.records
        total.value = res.data.total
      })
    }
    const returnIndex = () => {
      router.replace('/')
    }
    const pageChange = (pageNo) => {
      currentPage.value = pageNo - 1
    }

    getNewsList()

    return {
      currentPage,
      total,
      articles,
      currentNo,
      returnIndex,
      pageChange
    }
  }
};
</script>


<style scoped lang="stylus">
.back {
  cursor: pointer;
  color: #aaa;
  margin-top: 10px;
  display: inline-block;
}

.container-wrapper {
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  padding-bottom: 50px;
  position: relative;
}

.v-item-inner {
  height: 150px;
  display: flex;
  margin: 20px 0;
  border-bottom: 1px dashed #eee;
  padding-bottom: 5px;
}

.v-item-inner .image-wrapper {
  height: 100%;
  width: 300px;
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
  font-size: 30px;
}

.text-content {
  width: calc(100% - 300px);
  padding: 20px 10px;
  padding-top: 35px;
  box-sizing: border-box;
}

.time {
  color: #aaa;
  margin-top: 10px;
}

.pagenation {
  height: 50px;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0;
}
</style>
