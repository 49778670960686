<template>
  <div style="background: #f5eddf;">
    <el-image v-if="!isMobile"  fit="cover" :src="cSrc + (width ? `?x-oss-process=image/resize,m_fixed,w_${Math.ceil(width * devicePixelRatio)}` : '')">
      <template v #placeholder>
        <div class="image-slot">
          加载中<span class="dot">...</span>
        </div>
      </template>
      <template #error>
        <div class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </template>
    </el-image>
    <img v-else class="mobile-pic" fit="cover" :src="cSrc + (width ? `?x-oss-process=image/resize,m_fixed,w_${Math.ceil(width * devicePixelRatio)}` : '')">
  </div>
</template>
<script>
import { computed } from '@vue/runtime-core'
import { getRealMedialUrl } from '@/util'
export default {
  name: 'videoImage',
  props: {
    src: String,
    width: Number,
    isMobile: Boolean
  },
  setup (props) {
    const cSrc = computed(() => getRealMedialUrl(props.src))
    return {
      cSrc,
      devicePixelRatio: window.devicePixelRatio || 1
    }
  }
}
</script>
<style scoped>
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
  font-size: 20px;
}
.el-image {
  width: 100%;
  height: 100%;
}
.mobile-pic {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
</style>